import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import TemplateModal from '../modals/templateModal';
import { Row, Col } from 'react-bootstrap';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import {
  leftOrRightItems,
  isExistAnyHistory,
  isExistAnyEyeGlass,
  isExistAnyRehabData,
  isExistAnyOnExamination,
  isExistAnyInfertilityData,
  formatOldPrescriptionData,
  deleteConfirmation,
} from '../../../utils/helpers';
import ChiefComplainView from './views/chiefComplain';
import TreatmentPlanView from './views/treatmentPlan';
import DiagnosisView from './diagnosisView';
import FollowUpView from './views/followUp';
import AdviceView from './views/advice';
import InvestigationView from './views/investigation';
import RxView from './rxView';
import OnExaminationView from './views/onExamination';
import SpecialNoteView from './views/specialNoteView';
import RehabilitationView from './views/rehabilitationView';
import EyeGlassView from './views/eyeGlassView';
import InfertilityView from './views/infertility';
import { deleteRequest } from '../../../utils/axiosRequests';
import HistoryView from './views/historyView';

const PatientHistoryRight = ({
  patientData,
  prescriptions,
  setPrescriptions,
  appointment,
  note,
}) => {
  const prescriptionItems = useSelector(
    (state) => state?.prescriptionReducer?.prescriptionInfo?.prescriptionItems,
  );
  const [isTemplateModal, setIsTemplateModal] = useState(false);
  const [prescriptionData, setPrescriptionData] = useState({});

  const deletePrescription = (prescriptionId) => {
    deleteRequest(`prescriptions/${prescriptionId}`)
      .then((data) => {
        const result = prescriptions.filter(
          (prescription) => prescription.id !== prescriptionId,
        );
        setPrescriptions([...result]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createTemplateModal = (prescription) => {
    setPrescriptionData({
      organizationId: prescription?.organizationId?.id,
      chiefComplains: prescription?.chiefComplains,
      onExaminations: prescription?.onExaminations,
      diagnoses: prescription?.diagnoses,
      histories: prescription?.histories,
      medicines: prescription?.medicines,
      advices: prescription?.advices,
      followUps: prescription?.followUps,
      investigations: prescription?.investigations,
      eyeGlass: prescription?.eyeGlass,
      medicineIds: prescription?.medicineIds,
    });
    setIsTemplateModal(true);
  };

  const getFormattedAdvices = (advices) => {
    let formatedAdvices = [];
    let key = 'untitled';
    advices?.forEach((item) => {
      if (item?.title) {
        key = item?.title;
        formatedAdvices[key] = [item];
      } else {
        if (Array.isArray(formatedAdvices[key])) {
          formatedAdvices[key].push(item);
        } else {
          formatedAdvices[key] = [item];
        }
      }
    });
    return formatedAdvices;
  };

  const chiefComplainView = (element, selectedChiefComplains) => {
    if (selectedChiefComplains.length === 0) return false;

    return (
      <ChiefComplainView
        element={element}
        key={'chiefComplain'}
        prescriptionItems={prescriptionItems}
        selectedChiefComplains={selectedChiefComplains}
        isHistoryPage={true}
      />
    );
  };

  const treatmentPlanView = (element, selectedTreatmentPlans) => {
    if (selectedTreatmentPlans?.length === 0) return false;

    return (
      <TreatmentPlanView
        element={element}
        key={'treatmentPlan'}
        prescriptionItems={prescriptionItems}
        selectedTreatmentPlans={selectedTreatmentPlans}
        isHistoryPage={true}
      />
    );
  };

  const historyView = (element, selectedHistories) => {
    if (!isExistAnyHistory(selectedHistories)) return false;

    return (
      <HistoryView
        element={element}
        key={'history'}
        selectedHistories={selectedHistories}
        prescriptionItems={prescriptionItems}
        isHistoryPage={true}
      />
    );
  };

  const viewDiagnosis = (element, selectedDiagnosis, selectedStage) => {
    if (selectedDiagnosis.length === 0 && !selectedStage?.isShow) return false;

    return (
      <DiagnosisView
        element={element}
        key={'diagnosis'}
        selectedDiagnosis={selectedDiagnosis}
        prescriptionItems={prescriptionItems}
        selectedStage={selectedStage}
        isHistoryPage={true}
      />
    );
  };

  const followUpView = (element, selectedFollowUps) => {
    if (selectedFollowUps?.length === 0) return false;

    return (
      <FollowUpView
        element={element}
        key={'followup'}
        prescriptionItems={prescriptionItems}
        selectedFollowUps={selectedFollowUps}
        isHistoryPage={true}
      />
    );
  };

  const adviceView = (element, selectedAdvices) => {
    if (selectedAdvices.length === 0) return false;

    return (
      <AdviceView
        element={element}
        key={'advice'}
        prescriptionItems={prescriptionItems}
        selectedAdvices={selectedAdvices}
        isHistoryPage={true}
      />
    );
  };

  const investigationView = (element, selectedInvestigations) => {
    if (selectedInvestigations.length === 0) {
      return false;
    }
    return (
      <InvestigationView
        element={element}
        key={'investigation'}
        prescriptionItems={prescriptionItems}
        selectedInvestigations={selectedInvestigations}
        isHistoryPage={true}
      />
    );
  };

  const viewRx = (element, selectedMedicines) => {
    if (selectedMedicines.length === 0) {
      return false;
    }
    return (
      <RxView
        selectedMedicines={selectedMedicines}
        prescriptionItems={prescriptionItems}
        element={element}
        key={'rx'}
        isHistoryPage={true}
      />
    );
  };

  const onExaminationView = (element, selectedOnExamination) => {
    if (!isExistAnyOnExamination(selectedOnExamination)) {
      return false;
    }
    return (
      <OnExaminationView
        element={element}
        key={'onExamination'}
        selectedOnExamination={selectedOnExamination}
        prescriptionItems={prescriptionItems}
        isHistoryPage={true}
      />
    );
  };

  const specialNoteView = (element, specialNotes) => {
    if (specialNotes) {
      if (specialNotes?.length === 0) {
        return false;
      }
    } else {
      return false;
    }
    return (
      <SpecialNoteView
        selectedSpecialNotes={specialNotes}
        prescriptionItems={prescriptionItems}
        element={element}
        key={'specialNote'}
        isHistoryPage={true}
      />
    );
  };

  const viewRehabilitation = (element, selectedRehabilitation) => {
    if (!isExistAnyRehabData(selectedRehabilitation)) {
      return false;
    }
    return (
      <RehabilitationView
        selectedRehabilitation={selectedRehabilitation}
        element={element}
        key={'rehab'}
        prescriptionItems={prescriptionItems}
        isHistoryPage={true}
      />
    );
  };

  const eyeGlassView = (element, selectedEyeGlass) => {
    if (!isExistAnyEyeGlass(selectedEyeGlass)) {
      return false;
    }
    return (
      <EyeGlassView
        selectedEyeGlass={selectedEyeGlass}
        prescriptionItems={prescriptionItems}
        element={element}
        key={'eye-glass'}
        isHistoryPage={true}
      />
    );
  };

  const infertilityView = (element, selectedInfertilities) => {
    if (!isExistAnyInfertilityData(selectedInfertilities)) {
      return false;
    }
    return (
      <InfertilityView
        element={element}
        isHistoryPage={true}
        key={'infertility'}
        prescriptionItems={prescriptionItems}
        selectedInfertilities={selectedInfertilities}
      />
    );
  };

  const addNewRxButton = () => {
    return (
      <div className="add-new-rx mb-3">
        <span>
          <i className="fa fa-plus pr-2"></i>
          Add New Rx
        </span>
      </div>
    );
  };

  return (
    <div>
      {appointment && appointment?.status !== 'completed' ? (
        <Link
          to="/prescription"
          state={{
            appointment,
            dob: patientData?.dob,
            history: 'onlyAppointment',
          }}
        >
          {addNewRxButton()}
        </Link>
      ) : (
        <Link
          to={'/prescription'}
          state={{
            patientData: patientData,
            dob: patientData?.dob,
            history: 'new',
          }}
        >
          {addNewRxButton()}
        </Link>
      )}
      {prescriptions.length > 0 && (
        <Row className="mt-2 application-history dotted-border ml-0">
          <Col>
            <Accordion defaultActiveKey={[0]} alwaysOpen>
              {prescriptions?.map((data, index) => {
                if (data?.medicines?.length > 0) {
                  data.medicines = formatOldPrescriptionData(data.medicines);
                }
                return (
                  <Accordion.Item
                    eventKey={index}
                    key={index}
                    className="mb-3 prescription-history"
                  >
                    <Accordion.Header>
                      <div className="prescription-number-circle">
                        <div>{++index}</div>
                      </div>
                      <span className="accordion-title-text">
                        {data?.appointmentId?.appointmentDateTime
                          ? Moment(
                              data?.appointmentId?.appointmentDateTime,
                            ).format('D MMMM, YYYY')
                          : ''}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="right-edit-icon">
                        <span
                          className="create-template-btn"
                          onClick={() => createTemplateModal(data)}
                        >
                          <i className="fa fa-file"></i> Template
                        </span>
                        |
                        <Link
                          to={'/prescription'}
                          className="pres-edit"
                          state={{
                            prescription: data,
                            dob: data?.patientId?.dob,
                            note: note,
                            history: 'editFromHistory',
                          }}
                        >
                          <i className="fa fa-edit"></i> Edit
                        </Link>
                        |
                        <span
                          className="pres-del"
                          onClick={() =>
                            deleteConfirmation(deletePrescription, data.id)
                          }
                        >
                          <i className="fa fa-trash-alt"></i> Delete
                        </span>
                      </div>
                      <div className="row prescription-body pt-2">
                        {leftOrRightItems(prescriptionItems, 'left').length >
                          0 && (
                          <div className="prescription-body-left">
                            {leftOrRightItems(prescriptionItems, 'left').map(
                              (element) => {
                                if (element.name === 'chief-complain') {
                                  return chiefComplainView(
                                    element,
                                    data?.chiefComplains,
                                  );
                                } else if (element.name === 'treatment-plan') {
                                  return treatmentPlanView(
                                    element,
                                    data?.treatmentPlans,
                                  );
                                } else if (element.name === 'history') {
                                  return historyView(element, data?.histories);
                                } else if (element.name === 'diagnosis') {
                                  return viewDiagnosis(
                                    element,
                                    data?.diagnoses,
                                    data?.staging,
                                  );
                                } else if (element.name === 'follow-up') {
                                  return followUpView(element, data?.followUps);
                                } else if (element.name === 'investigation') {
                                  return investigationView(
                                    element,
                                    data?.investigations,
                                  );
                                } else if (element.name === 'rx') {
                                  return viewRx(element, data?.medicines);
                                } else if (element.name === 'on-examination') {
                                  return onExaminationView(
                                    element,
                                    data?.onExaminations,
                                  );
                                } else if (element.name === 'special-note') {
                                  return specialNoteView(
                                    element,
                                    data?.specialNotes,
                                  );
                                } else if (element.name === 'rehabilitation') {
                                  return viewRehabilitation(
                                    element,
                                    data?.rehabilitation,
                                  );
                                } else if (element.name === 'advice') {
                                  return adviceView(element, data?.advices);
                                } else if (element.name === 'eye-glass') {
                                  return eyeGlassView(element, data?.eyeGlass);
                                } else if (element.name === 'infertility') {
                                  return infertilityView(
                                    element,
                                    data?.infertilities,
                                  );
                                }
                              },
                            )}
                          </div>
                        )}
                        {leftOrRightItems(prescriptionItems, 'right').length >
                          0 && (
                          <div className="prescription-body-right">
                            {leftOrRightItems(prescriptionItems, 'right').map(
                              (element) => {
                                if (element.name === 'chief-complain') {
                                  return chiefComplainView(
                                    element,
                                    data?.chiefComplains,
                                  );
                                } else if (element.name === 'treatment-plan') {
                                  return treatmentPlanView(
                                    element,
                                    data?.treatmentPlans,
                                  );
                                } else if (element.name === 'history') {
                                  return historyView(element, data?.histories);
                                } else if (element.name === 'diagnosis') {
                                  return viewDiagnosis(
                                    element,
                                    data?.diagnoses,
                                    data?.staging,
                                  );
                                } else if (element.name === 'follow-up') {
                                  return followUpView(element, data?.followUps);
                                } else if (element.name === 'advice') {
                                  return adviceView(element, data?.advices);
                                } else if (element.name === 'rx') {
                                  return viewRx(element, data?.medicines);
                                } else if (element.name === 'on-examination') {
                                  return onExaminationView(
                                    element,
                                    data?.onExaminations,
                                  );
                                } else if (element.name === 'special-note') {
                                  return specialNoteView(
                                    element,
                                    data?.specialNotes,
                                  );
                                } else if (element.name === 'rehabilitation') {
                                  return viewRehabilitation(
                                    element,
                                    data?.rehabilitation,
                                  );
                                } else if (element.name === 'eye-glass') {
                                  return eyeGlassView(element, data?.eyeGlass);
                                } else if (element.name === 'infertility') {
                                  return infertilityView(
                                    element,
                                    data?.infertilities,
                                  );
                                }
                              },
                            )}
                          </div>
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
            <TemplateModal
              isTemplateModal={isTemplateModal}
              setIsTemplateModal={setIsTemplateModal}
              templateInput={prescriptionData}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default memo(PatientHistoryRight);
